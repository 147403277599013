const env = process.env.APP_ENV || '';
const isDevelopment = env === 'development';
const i18next = require('i18next');

module.exports = {
    serializeConfig: false,
    i18n: {
        defaultLocale: 'sv',
        locales: ['sv'],
        localeDetection: false,
        ...(isDevelopment && {debug: true}),
    },
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: function(value, format, lang, options) {
            return value;
        },
    },
};
